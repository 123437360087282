<template>
  <div class="section nobg nomargin home-faq-sec">
    <div class="container">
      <div class="heading-block center">
        <h2>Let's Talk</h2>
      </div>
      <div class="row">
        <div class="col-md-4" v-for="n in list" :key="n">
          <a :href="n.to" class="button button-icon">
            <img :src="n.src" />
            <span>{{ n.text }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-faq-2.png",
          text: "Visit Our FAQ Page",
          to: "/faq"
        },
        {
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-facebook-blog.png",
          text: "Visit Our Facebook Page",
          to: this.facebookLink
        },
        {
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-twitter-blog.png",
          text: "Visit Our Twitter Page",
          to: this.twitterLink
        },
      ],
    };
  },
};
</script>
